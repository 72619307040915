import React from 'react';
import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import { signOut } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase";
import { createImageFromInitials } from "../utils/DisplayImageGenerator";
import * as Constants from "../utils/Constants";

function Header({ userDisplayName, userEmail }) {
  const location = useLocation();

  function getPageTitle() {
    const path = location.pathname;
    if (Constants.ROUTE_CONFIG[path]) {
      return Constants.ROUTE_CONFIG[path].title;
    }
    
    const baseRoute = Object.keys(Constants.ROUTE_CONFIG).find(route => 
      path.startsWith(route) && route !== '/'
    );
    
    return baseRoute 
      ? Constants.ROUTE_CONFIG[baseRoute].title 
      : Constants.ROUTE_CONFIG['/dashboard'].title;
  }

  async function handleLogout() {
    try {
      await signOut(auth);
      window.location.href = "/signin";
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  }

  const toggleSidebar = () => {
    const drawerCheckbox = document.getElementById('my-drawer-2');
    if (drawerCheckbox) {
      drawerCheckbox.checked = !drawerCheckbox.checked;
    }
  };

  return (
    <div className="navbar flex justify-between bg-base-100 z-10 shadow-md">
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="btn btn-ghost drawer-button lg:hidden"
        >
          <Bars3Icon className="h-5 w-5" />
        </button>
        <h1 className="text-2xl font-semibold ml-2">{getPageTitle()}</h1>
      </div>

      <div className="order-last">
        <div className="dropdown dropdown-end ml-4">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img
                src={
                  userDisplayName
                    ? createImageFromInitials(500, userDisplayName)
                    : createImageFromInitials(500, userEmail)
                }
                alt="profile"
              />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 z-50"
          >
            <li className="justify-between">
              <Link to={"/profile"}>
                Profile Settings
                <span className="badge">New</span>
              </Link>
            </li>
            <div className="divider mt-0 mb-0"></div>
            <li>
              <button onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;