import React, { useState, useEffect, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getFirestore, doc, updateDoc, arrayRemove, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import TitleCard from "../components/Cards/TitleCard";
import PerformerCard from "../components/GenericDashboardComponents/PerformerCard";
import PerformerSearchBar from "../components/GenericDashboardComponents/PerformerSearchBar";
import Loader from "../utils/Loader";
import { getSelectedCompanyId } from "../utils/companyStorage";
import { getFilterDatesConditions } from "../utils/DateRange";
import { getApiUrl } from "../utils/apiConfig";
import Layout from "../containers/Layout";
import PerformerPayWall from "../components/GenericDashboardComponents/PerformerPayWall";
import FilterOptions from "../components/FilterOptions";
import * as Constants from "../utils/Constants";
import useAuthenticatedUser from "../hooks/AuthenticateUser";

const calculateAverage = (scores) => {
  const validScores = scores.filter(
    (score) => score !== undefined && score !== null && score > 0
  );
  return validScores.length > 0
    ? Math.round(
        validScores.reduce((total, current) => total + current, 0) /
          validScores.length
      )
    : 0;
};

const mapScoresToCategories = (scoreMap) => {
  if (!scoreMap) return {};
  return {
    Wellbeing: calculateAverage([
      scoreMap.psychological_safety_score,
      scoreMap.positivity_score,
      scoreMap.energy_score,
      scoreMap.physical_environment_score,
      scoreMap.burnout_score,
      scoreMap.belonging_score,
    ]),
    Performance: calculateAverage([
      scoreMap.recognition_score,
      scoreMap.productivity_score,
      scoreMap.motivation_score,
      scoreMap.focus_score,
    ]),
    Engagement: calculateAverage([
      scoreMap.collaboration_score,
      scoreMap.engagement_score,
      scoreMap.empowerment_score,
      scoreMap.sentiment_score,
      scoreMap.energy_score,
      scoreMap.communication_score,
    ]),
    Growth: calculateAverage([
      scoreMap.recognition_score,
      scoreMap.autonomy_score,
      scoreMap.career_growth_score,
      scoreMap.personal_satisfaction_score,
    ]),
    Leadership_and_Vision: calculateAverage([
      scoreMap.manager_effectiveness_score,
      scoreMap.alignment_score,
      scoreMap.leadership_sentiment_score,
      scoreMap.confidence_score,
    ]),
  };
};

const hasEnoughData = (dimensionScores, numberOfMessages) => {
  const mainCategories = [
    "Wellbeing",
    "Performance",
    "Engagement",
    "Growth",
    "Leadership_and_Vision",
  ];
  const validScores = mainCategories.filter(
    (category) => dimensionScores[category] > 0
  );
  return validScores.length >= 3 && numberOfMessages > 0;
};

const PerformerView = ({ performerType }) => {
  const { user, isLoading: authLoading } = useAuthenticatedUser();
  const [performersData, setPerformersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUserUid, setCurrentUserUid] = useState(null);
  const [error, setError] = useState(null);
  const [userTier, setUserTier] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(null);
  const [filters, setFilters] = useState({ scoreType: "Sentiment" });
  const [scoreType, setScoreType] = useState("Sentiment");
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchSelectedCompanyId = async () => {
        try {
          const companyId = await getSelectedCompanyId(user.uid);
          const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = userDocSnapshot.data();
          setUserTier(userData?.userTier ?? true);
          setIsDemoUser(userData?.isDemoUser ?? true);
          setCurrentUserUid(companyId);
        } catch (error) {
          console.error("Error fetching company data:", error);
        } finally {
          setIsInitialized(true);
        }
      };
      fetchSelectedCompanyId();
    }
  }, [user]);

  useEffect(() => {
    if (currentUserUid && filters) {
      fetchPerformers(currentUserUid);
    }
  }, [currentUserUid, performerType, filters]);

  const handleFilterChange = useCallback(
    (newFilters) => {
      if (!currentUserUid) return;

      if (currentUserUid === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        newFilters = { ...newFilters, location: "Belgrade" };
      }

      if (newFilters.scoreType && newFilters.scoreType !== scoreType) {
        handleScoreTypeChange(newFilters.scoreType);
      }

      setFilters(newFilters);
      fetchPerformers(currentUserUid);
    },
    [currentUserUid, scoreType]
  );

  const fetchPerformers = async (companyId) => {
    setLoading(true);
    setError(null);
    setPerformersData([]);

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-high-performers");
      let url = new URL(endpointUrl);

      url.searchParams.append("company_uuid", companyId);
      url.searchParams.append("performerType", performerType);

      if (filters) {
        if (filters.start_date || filters.end_date) {
          const filterDateConditions = getFilterDatesConditions(
            filters.start_date,
            filters.end_date
          );
          
          if (filterDateConditions[0]) {
            const startDate = filterDateConditions[0].split('=')[1];
            url.searchParams.append("start_date", startDate);
          }
          
          if (filterDateConditions[1]) {
            const endDate = filterDateConditions[1].split('=')[1];
            url.searchParams.append("end_date", endDate);
          }
        }

        for (const [key, value] of Object.entries(filters)) {
          if (
            value &&
            key !== "scoreType" &&
            key !== "dateRange" &&
            key !== "generation" &&
            key !== "start_date" &&
            key !== "end_date"
          ) {
            if (Array.isArray(value)) {
              value.forEach(v => url.searchParams.append(key, v));
            } else {
              url.searchParams.append(key, value);
            }
          }
        }
      }

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setPerformersData(data || []);
    } catch (error) {
      console.error(`Error fetching ${performerType} data:`, error);
      setError(`Error fetching ${performerType} data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  const handleRemovePerformer = async (performerId) => {
    const db = getFirestore();
    const user = auth.currentUser;

    if (!user) {
      console.error("No authenticated user");
      return;
    }

    const companyRef = doc(db, "companies", currentUserUid);

    try {
      await updateDoc(companyRef, {
        [performerType]: arrayRemove(performerId),
      });

      setPerformersData((prevData) =>
        prevData.filter((p) => p.employee_uuid !== performerId)
      );
    } catch (error) {
      console.error("Error removing performer:", error);
    }
  };

  const title =
    performerType === "highPerformers" ? "High Performers" : "Real-Time Performers";

  const renderContent = () => (
    <div className="space-y-6">
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-yellow-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              This feature is currently in beta.
            </p>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <FilterOptions
          user={user}
          companyUid={currentUserUid}
          onFilterChange={handleFilterChange}
        />
      </div>

      <TitleCard title={title}>
        <div className="mb-4">
          <PerformerSearchBar
            performerType={performerType}
            onUpdate={() => fetchPerformers(currentUserUid)}
            filters={filters}
          />
        </div>
        <Loader loading={loading} loadingText={`Fetching ${title}`}>
          {error ? (
            <p className="text-center text-red-500 text-xl mt-8">{error}</p>
          ) : performersData.length > 0 ? (
            <div>
              {performersData.map((performer) => {
                const dimensionScores = mapScoresToCategories(performer.scores);
                const hasData = hasEnoughData(
                  dimensionScores,
                  performer.number_of_messages
                );
                const overallSentiment = hasData
                  ? calculateAverage(Object.values(dimensionScores))
                  : 0;
                return (
                  <PerformerCard
                    performerType={performerType}
                    key={performer.employee_uuid}
                    id={performer.employee_uuid}
                    name={`${performer.first_name} ${performer.last_name}`}
                    team={performer.job_title}
                    department={performer.department}
                    overallSentiment={overallSentiment}
                    dimensionScores={dimensionScores}
                    onRemove={handleRemovePerformer}
                    hasEnoughData={hasData}
                    removeButton={
                      <button
                        className="btn btn-error btn-sm"
                        onClick={() =>
                          handleRemovePerformer(performer.employee_uuid)
                        }
                      >
                        Remove
                      </button>
                    }
                  />
                );
              })}
            </div>
          ) : (
            <p className="text-center text-gray-500 text-xl mt-8">
              No {title} selected. Please use the search bar to add{" "}
              {title.toLowerCase()}.
            </p>
          )}
        </Loader>
      </TitleCard>
    </div>
  );

  const isPageLoading = authLoading || !isInitialized;

  if (isPageLoading) {
    return (
      <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
        <div className="flex justify-center items-center min-h-[400px]">
          <Loader size={50} color={"#123abc"} loading={true} loadingText={"Loading..."} />
        </div>
      </Layout>
    );
  }

  if (!isDemoUser && userTier === "Base") {
    return (
      <PerformerPayWall
        userTier={userTier}
        displayName={user?.displayName}
        email={user?.email}
      />
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
        {renderContent()}
      </Layout>
    </LocalizationProvider>
  );
};

export default PerformerView;