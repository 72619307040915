import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  FaBolt,
  FaBuilding,
  FaBullseye,
  FaBurn,
  FaChartBar,
  FaChartLine,
  FaComments as FaCommentsAlt,
  FaCompass,
  FaCrown,
  FaGem,
  FaHandshake,
  FaHeart,
  FaRocket,
  FaShieldAlt,
  FaSmile,
  FaSmile as FaSmileAlt,
  FaThumbsUp,
  FaTrophy,
  FaUnlockAlt,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Layout from "../containers/Layout";
import { checkAuth } from "../utils/AuthCheck";

const DefinitionGlossary = () => {
  const [expandedScoreType, setExpandedScoreType] = useState(null);

  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [authComplete, setAuthComplete] = useState(false);

  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
        setAuthComplete(true);
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    }

    authenticateUser();
  }, [user]);

  const scoreDefinitions = [
    {
      title: "Burnout Score",
      definition: "Real-time analysis of digital interaction patterns, communication metadata, and work behaviors across messaging and collaboration platforms to identify burnout risk levels and trends.",
      icon: <FaBurn size={30} />,
      details: 
        "Burnout detection leverages integrated data from enterprise communication platforms (Slack, Teams, Mattermost) and HR systems (HiBob, BambooHR) to identify early warning signs and trends. Key signals include changes in message timing/frequency, communication load, and after-hours digital activity. Advanced machine learning models analyze both explicit content and implicit signals like response patterns and platform engagement. The system processes both structured HR data and unstructured communication data to provide early warning of potential burnout conditions.",
      calculation:
        "Real-time analysis combining: 1) Communication platform metrics (message frequency, response times, after-hours activity), 2) Meeting analytics (Zoom participation patterns, schedule density), 3) HR system data (time-off patterns, role changes), 4) Natural Language Processing of communication content for stress indicators, and 5) Temporal pattern analysis across platforms. ML models weight recent patterns more heavily and adjust for team-specific baselines.",
    },
    {
      title: "Energy Score",
      definition: "Measures vitality and engagement through analysis of cross-platform activity patterns, interaction quality, and work rhythm data from integrated workplace tools.",
      icon: <FaBolt size={30} />,
      details:
        "Energy levels are assessed through comprehensive analysis of digital activity patterns across communication and collaboration platforms. The system tracks interaction frequency, response vigor, and meeting participation through platforms like Slack, Teams, Mattermost, and Zoom. Advanced analytics identify sustainable engagement patterns versus potential depletion indicators. Real-time monitoring enables early detection of energy shifts and their impact on team dynamics.",
      calculation:
        "Integrates data from: 1) Communication platforms (message vitality, response patterns), 2) Video conferencing systems (meeting engagement levels), 3) HR platforms (attendance patterns, engagement indicators), 4) Message content analysis (sentiment and tone), and 5) Cross-platform activity rhythms. Machine learning algorithms identify optimal energy patterns while flagging potential concerns.",
    },
    {
      title: "Psychological Safety Score",
      definition: "Evaluates team trust levels through analysis of communication patterns, collaboration behaviors, and interaction dynamics across messaging and meeting platforms.",
      icon: <FaShieldAlt size={30} />,
      details:
        "Psychological safety is measured through sophisticated analysis of digital interactions across enterprise communication channels. The system processes data from Slack, Teams, Mattermost, and Zoom to identify indicators of trust and openness. Natural language processing identifies speaking up behaviors, constructive disagreement patterns, and inclusive communication markers. Real-time analysis enables proactive identification of team dynamics that impact psychological safety.",
      calculation:
        "Processes data from: 1) Team messaging platforms (discussion patterns, feedback dynamics), 2) Video conferencing (participation equity, voice distribution), 3) HR systems (team stability, reporting patterns), 4) Cross-platform interaction analysis, and 5) Advanced sentiment analysis of communication content. ML models identify psychological safety indicators while respecting privacy and context.",
    },
    {
      title: "Belonging Score",
      definition: "Measures inclusion and connection through analysis of interaction patterns, collaboration networks, and engagement data across enterprise platforms.",
      icon: <FaHeart size={30} />,
      details:
        "Belonging is evaluated through analysis of digital interaction patterns and collaboration networks across integrated platforms. The system processes data from Slack, Teams, Mattermost, and Zoom to identify inclusion indicators and connection patterns. Advanced machine learning models analyze both formal team interactions and informal social connections to assess belonging levels. Integration with HR systems (HiBob, BambooHR) provides organizational context for deeper insight.",
      calculation:
        "Analyzes data from: 1) Communication platforms (interaction networks, channel participation), 2) Meeting systems (group dynamics, participation patterns), 3) HR systems (team structures, organizational relationships), 4) Social interaction analysis (peer connections, collaboration patterns), and 5) Natural language processing of group communications. AI algorithms map social networks while maintaining privacy.",
    },
    {
      title: "Positivity Score",
      definition: "Measures constructive attitudes and optimistic approaches through sentiment analysis of communications and interaction patterns across integrated platforms.",
      icon: <FaSmile size={30} />,
      details:
        "Positivity is assessed through comprehensive sentiment analysis of digital communications across enterprise messaging platforms. The system analyzes message content, feedback tone, and interaction patterns from Slack, Teams, and Mattermost. Advanced natural language processing evaluates both explicit sentiment and implicit emotional indicators. Real-time monitoring enables tracking of mood trends and their impact on team dynamics.",
      calculation:
        "Processes data from: 1) Enterprise messaging platforms (sentiment analysis, emoji usage), 2) Video conferencing (interaction tone, engagement quality), 3) Cross-platform communication patterns, 4) Advanced linguistic analysis of message content, and 5) Temporal mood pattern analysis. ML models evaluate emotional tone while considering team and cultural context.",
    },
    {
      title: "Productivity Score",
      definition: "Quantifies work effectiveness through analysis of communication patterns, collaboration efficiency, and engagement quality across integrated platforms.",
      icon: <FaChartLine size={30} />,
      details:
        "Productivity is measured through comprehensive analysis of digital work patterns across enterprise platforms. The system integrates data from communication tools (Slack, Teams, Mattermost) and meeting platforms (Zoom) to assess work effectiveness. Machine learning models analyze both individual and team productivity indicators while considering role context from HR systems.",
      calculation:
        "Combines analysis of: 1) Communication efficiency (message patterns, response effectiveness), 2) Meeting productivity (Zoom engagement quality, session effectiveness), 3) Collaboration patterns (cross-team interaction efficiency), 4) Work rhythm analysis (focus time patterns), and 5) HR context (role expectations, team structures).",
    },
    {
      title: "Motivation Score",
      definition: "Evaluates drive and engagement through analysis of initiative patterns and participation quality across communication platforms.",
      icon: <FaRocket size={30} />,
      details:
        "Motivation levels are assessed through analysis of digital engagement patterns and initiative indicators. The system processes interaction data from enterprise communication platforms and meeting systems to identify motivation markers. Natural language processing evaluates enthusiasm and commitment levels in communications. Integration with HR data provides context for role-appropriate motivation assessment.",
      calculation:
        "Measures through: 1) Communication platform engagement (initiative patterns, participation quality), 2) Meeting participation analysis (Zoom engagement levels), 3) HR system context (role progression, development activities), 4) Natural language processing of interaction content, and 5) Cross-platform activity pattern analysis.",
    },
    {
      title: "Focus Score",
      definition: "Assesses concentration effectiveness through analysis of work patterns and digital interaction behaviors across integrated platforms.",
      icon: <FaBullseye size={30} />,
      details:
        "Focus is evaluated through analysis of digital work patterns and interaction behaviors. The system processes data from communication platforms to identify concentrated work periods and interruption patterns. Machine learning models analyze both individual and team-level focus indicators while considering role-specific needs.",
      calculation:
        "Analyzes: 1) Communication platform patterns (message timing, response behaviors), 2) Meeting schedule analysis (calendar patterns, break frequency), 3) Activity concentration patterns, 4) Interruption analysis, and 5) Deep work period identification across integrated platforms.",
    },
    {
      title: "Recognition Score",
      definition: "Measures appreciation and acknowledgment patterns through analysis of peer interactions and communication content across enterprise platforms.",
      icon: <FaTrophy size={30} />,
      details:
        "Recognition patterns are identified through analysis of digital interactions across communication platforms. The system processes data from Slack, Teams, and Mattermost to identify appreciation indicators. Natural language processing identifies both explicit recognition and implicit appreciation signals. Integration with HR systems provides organizational context for recognition patterns.",
      calculation:
        "Evaluates through: 1) Communication platform analysis (appreciation messages, peer recognition), 2) Natural language processing of interaction content, 3) HR system integration (formal recognition programs), 4) Cross-platform acknowledgment patterns, and 5) Team-level recognition dynamics.",
    },
    {
      title: "Engagement Score",
      definition: "Evaluates depth of commitment through analysis of interaction patterns and platform participation across integrated systems.",
      icon: <FaHandshake size={30} />,
      details:
        "Engagement is measured through comprehensive analysis of participation patterns across communication and collaboration platforms. The system integrates data from messaging platforms (Slack, Teams, Mattermost) and meeting systems (Zoom) with HR data (HiBob, BambooHR) to create a holistic view of engagement. Machine learning models identify patterns of sustained engagement versus surface-level participation.",
      calculation:
        "Combines analysis of: 1) Communication platform engagement (active participation, initiative), 2) Meeting contribution patterns (Zoom interaction quality), 3) HR system indicators (tenure, role growth), 4) Cross-platform activity patterns, and 5) Natural language processing of interaction content.",
    },
    {
      title: "Empowerment Score",
      definition: "Measures autonomy and influence through analysis of decision-making patterns and initiative indicators in digital interactions.",
      icon: <FaGem size={30} />,
      details:
        "Empowerment is assessed through analysis of digital interaction patterns and decision-making indicators. The system processes communication data to identify leadership behaviors and autonomous actions. Natural language processing evaluates confidence and authority in communications. Integration with HR systems provides role context for empowerment assessment.",
      calculation:
        "Analyzes: 1) Communication platform patterns (decision-making indicators, initiative signals), 2) Meeting dynamics (leadership behaviors, influence patterns), 3) HR system context (role authority, responsibilities), 4) Natural language processing of communication style, and 5) Cross-platform influence analysis.",
    },
    {
      title: "Collaboration Score",
      definition: "Evaluates team interaction effectiveness through analysis of cross-platform communication patterns and group dynamics.",
      icon: <FaUsers size={30} />,
      details:
        "Collaboration is measured through comprehensive analysis of interaction patterns across enterprise platforms. The system processes data from Slack, Teams, Mattermost, and Zoom to identify effective teamwork patterns. Machine learning models analyze both structured and unstructured collaboration indicators while considering team composition and goals.",
      calculation:
        "Combines: 1) Communication platform analysis (team interaction patterns), 2) Meeting effectiveness measures (Zoom collaboration quality), 3) Cross-functional communication analysis, 4) Team network mapping, and 5) Natural language processing of group interactions.",
    },
    {
      title: "Communication Score",
      definition: "Evaluates information exchange effectiveness through analysis of messaging patterns and interaction quality across integrated platforms.",
      icon: <FaCommentsAlt size={30} />,
      details:
        "Communication effectiveness is measured through comprehensive analysis of interactions across enterprise messaging platforms. The system analyzes patterns from Slack, Teams, and Mattermost, combined with Zoom meeting dynamics. Natural language processing evaluates message clarity, response effectiveness, and information flow patterns.",
      calculation:
        "Analyzes data from: 1) Enterprise messaging platforms (interaction patterns, response times), 2) Video conferencing (meeting effectiveness, participation metrics), 3) Cross-channel communication flows, 4) Message content quality analysis, and 5) Team interaction networks.",
    },
    {
      title: "Career Growth Score",
      definition: "Assesses professional development through analysis of role progression, skill development indicators, and growth opportunities.",
      icon: <FaChartBar size={30} />,
      details:
        "Career growth is evaluated through integration of HR system data (HiBob, BambooHR) with interaction patterns across communication platforms. The system analyzes role changes, skill development indicators, and growth opportunity engagement. Machine learning models identify career progression patterns while considering organizational context.",
      calculation:
        "Processes: 1) HR system data (role changes, promotions), 2) Skill development indicators, 3) Growth opportunity engagement, 4) Natural language processing of development-related communications, and 5) Cross-platform professional growth signals.",
    },
    {
      title: "Autonomy Score",
      definition: "Measures level of independence and self-direction through analysis of decision-making patterns and work behaviors across integrated platforms.",
      icon: <FaUnlockAlt size={30} />,
      details: 
        "Autonomy is evaluated through comprehensive analysis of independent decision-making and work patterns across enterprise platforms. The system processes data from communication tools (Slack, Teams, Mattermost) and HR systems (HiBob, BambooHR) to identify self-directed work patterns and decision ownership. Machine learning models analyze both explicit indicators of autonomous action and implicit patterns of independent work behaviors. Integration with meeting platforms (Zoom) provides additional context for autonomy in collaborative settings.",
      calculation:
        "Analyzes data from: 1) Communication platform patterns (decision initiation, independent actions), 2) Meeting dynamics (self-directed contributions, leadership behaviors), 3) HR system context (role autonomy levels, responsibility scope), 4) Natural language processing of decision-making communications, and 5) Cross-platform initiative patterns. AI algorithms identify autonomous behavior patterns while considering role and organizational context.",
    },
    {
      title: "Personal Satisfaction Score",
      definition: "Evaluates individual fulfillment and work-life harmony through analysis of engagement patterns and sentiment across integrated platforms.",
      icon: <FaSmileAlt size={30} />,
      details:
        "Personal satisfaction is measured through sophisticated analysis of digital interaction patterns and sentiment indicators. The system integrates data from communication platforms (Slack, Teams, Mattermost), meeting systems (Zoom), and HR platforms (HiBob, BambooHR) to create a comprehensive view of individual fulfillment. Advanced natural language processing identifies both explicit and implicit satisfaction indicators, while machine learning models analyze work-life harmony patterns. Real-time monitoring enables early identification of satisfaction trends and potential areas for improvement.",
      calculation:
        "Combines analysis of: 1) Communication sentiment patterns (message tone, interaction quality), 2) Work hour patterns (schedule flexibility, after-hours activity), 3) HR system indicators (time-off utilization, role satisfaction), 4) Meeting participation quality (engagement levels, energy indicators), and 5) Natural language processing of personal expression in communications. ML models evaluate satisfaction trends while considering individual work styles and preferences.",
    },
    {
      title: "Leadership Sentiment Score",
      definition: "Evaluates perception of leadership effectiveness through analysis of team interactions and communication patterns with leaders.",
      icon: <FaCrown size={30} />,
      details:
        "Leadership sentiment is assessed through analysis of digital interactions involving organizational leaders. The system processes communication data from enterprise platforms to identify leadership effectiveness indicators. Natural language processing evaluates both explicit and implicit leadership sentiment signals. Integration with HR data provides organizational structure context.",
      calculation:
        "Analyzes: 1) Leader-team communication patterns, 2) Meeting dynamics with leadership presence, 3) Natural language processing of leadership-related communications, 4) Cross-platform leadership interaction analysis, and 5) Team response patterns to leadership initiatives.",
    },
    {
      title: "Manager Effectiveness Score",
      definition: "Measures management impact through analysis of team interactions, communication patterns, and leadership behaviors.",
      icon: <FaUserTie size={30} />,
      details:
        "Manager effectiveness is evaluated through comprehensive analysis of digital interactions between managers and their teams. The system processes data from communication platforms and meeting systems to identify effective management patterns. Machine learning models analyze both direct management activities and their impact on team dynamics.",
      calculation:
        "Combines: 1) Manager-team communication analysis, 2) Meeting effectiveness measures, 3) Team performance indicators, 4) Natural language processing of management interactions, and 5) Cross-platform leadership behavior analysis.",
    },
    {
      title: "Confidence Score",
      definition: "Evaluates self-assurance through analysis of communication patterns and interaction behaviors across enterprise platforms.",
      icon: <FaThumbsUp size={30} />,
      details:
        "Confidence is measured through analysis of digital communication patterns and interaction behaviors. The system processes data from enterprise platforms to identify confidence indicators in professional interactions. Natural language processing evaluates assertion levels and decision-making patterns in communications.",
      calculation:
        "Analyzes: 1) Communication style patterns, 2) Meeting participation behaviors, 3) Decision-making indicators, 4) Natural language processing of confidence markers, and 5) Cross-platform interaction analysis.",
    },
    {
      title: "Alignment Score",
      definition: "Measures coherence between individual actions and organizational goals through analysis of digital interaction patterns.",
      icon: <FaCompass size={30} />,
      details:
        "Alignment is evaluated through analysis of digital interactions and their relationship to organizational objectives. The system integrates HR system data with communication patterns to assess goal alignment. Machine learning models identify alignment indicators while considering role context and team objectives.",
      calculation:
        "Combines: 1) Goal-related communication analysis, 2) HR system objective alignment, 3) Team coordination patterns, 4) Natural language processing of strategy-related communications, and 5) Cross-platform alignment indicators.",
    },
    {
      title: "Physical Environment Score",
      definition: "Assesses work environment effectiveness through analysis of digital platform usage patterns and remote collaboration indicators.",
      icon: <FaBuilding size={30} />,
      details:
        "Environment effectiveness is evaluated through analysis of digital work patterns and collaboration behaviors. The system processes data from communication platforms and video conferencing to assess remote and hybrid work effectiveness. Integration with HR systems provides context about work arrangements and location patterns. Machine learning models identify optimal working patterns and potential environmental challenges.",
      calculation:
        "Analyzes patterns from: 1) Communication platform usage (work hour patterns, location indicators), 2) Video conferencing (meeting effectiveness by environment), 3) HR systems (work arrangement data, location information), 4) Activity pattern analysis across time zones, and 5) Environmental impact indicators on collaboration effectiveness.",
    },
  ];

  const toggleScoreType = (title) => {
    setExpandedScoreType(expandedScoreType === title ? null : title);
  };

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <div className="px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="space-y-6 p-8 bg-white rounded-3xl shadow-xl"
        >
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-4">
            Definition Glossary
          </h2>
          {scoreDefinitions.map((scoreType, index) => (
            <motion.div
              key={index}
              className="bg-gray-50 rounded-lg shadow-sm overflow-hidden"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
            >
              <motion.div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => toggleScoreType(scoreType.title)}
              >
                <div className="flex items-center">
                  <div className="p-2 mr-4 bg-white rounded-full shadow">
                    {scoreType.icon}
                  </div>
                  <h3 className="text-2xl font-semibold">{scoreType.title}</h3>
                </div>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{
                    rotate: expandedScoreType === scoreType.title ? 180 : 0,
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {expandedScoreType === scoreType.title ? (
                    <IoIosArrowUp size={24} />
                  ) : (
                    <IoIosArrowDown size={24} />
                  )}
                </motion.div>
              </motion.div>
              <AnimatePresence>
                {expandedScoreType === scoreType.title && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="p-6 bg-white"
                  >
                    <div className="mb-4">
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Definition
                      </h4>
                      <p className="text-gray-600">{scoreType.definition}</p>
                    </div>
                    <div className="mb-4">
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Details
                      </h4>
                      <p className="text-gray-600">{scoreType.details}</p>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Calculation
                      </h4>
                      <p className="text-gray-600">{scoreType.calculation}</p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Layout>
  );
};

export default DefinitionGlossary;
