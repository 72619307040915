import React, { useState, useEffect, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import FilterOptions from "../components/FilterOptions";
import Layout from "../containers/Layout";
import { auth, db } from "../firebase";
import "../index.css";
import * as Constants from "../utils/Constants";
import { getFilterDatesConditions } from "../utils/DateRange";
import Loader from "../utils/Loader";
import {
  formatDepartments,
  getCompanyAverage,
  getRandomColor,
} from "../utils/TimeSeriesUtils";
import { getSelectedCompanyId } from "../utils/companyStorage";
import validatePayment from "../utils/ValidatePayment";
import { getApiUrl } from "../utils/apiConfig";
import { checkSandBoxStatus } from "../utils/checkSandboxStatus";
import HRISIntegrationAlert from "../components/GenericDashboardComponents/HRISIntegrationAlert";
import Reports from "../components/GenericDashboardComponents/Reports";

const _ = require("lodash");
Chart.register(CategoryScale);

export default function GenericDashboard() {
  const [user, setUser] = useState(null);
  const [totalDataPoints, setTotalDataPoints] = useState(null);
  const [scoreMetadata, setScoreMetadata] = useState(null);
  const [scoreType, setScoreType] = useState("Sentiment");
  const [scoreByTeamData, setScoreByTeamData] = useState(null);
  const [teamNames, setTeamNames] = useState(null);
  const [additionalTeams, setAdditionalTeams] = useState(null);
  const [scoreTrendDayOverDayData, setScoreTrendDayOverDayData] = useState(null);
  const [minimalScoreTrendLineData, setMinimalScoreTrendLineData] = useState(null);
  const [scoreByTopData, setScoreByTopData] = useState(null);
  const [scoreByBottomData, setScoreByBottomData] = useState(null);
  const [scoreDistributionData, setScoreDistributionData] = useState(null);
  const [messageScoreAveragesData, setMessageScoreAveragesData] = useState(null);
  const [heatmapData, setHeatmapData] = useState(null);
  const [processedMessages, setProcessedMessages] = useState(null);
  const [overallScoresAndBenchmark, setOverallScoresAndBenchmark] = useState(null);
  const [negativeScoreEmployeeContributions, setNegativeScoreEmployeeContributions] = useState(null);
  const [neutralScoreEmployeeContributions, setNeutralScoreEmployeeContributions] = useState(null);
  const [positiveScoreEmployeeContributions, setPositiveScoreEmployeeContributions] = useState(null);
  
  // Add new loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isScoreDataLoading, setIsScoreDataLoading] = useState(true);
  const [isTimeDataLoading, setIsTimeDataLoading] = useState(true);
  const [isContributionsLoading, setIsContributionsLoading] = useState(true);
  const [isMetadataLoading, setIsMetadataLoading] = useState(true);
  const [isHeatmapLoading, setIsHeatmapLoading] = useState(true);

  const teamColorMap = {};

  const [filters, setFilters] = useState(null);
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] =
    useState("");

  // Recommendation Variables
  const [recommendationScores, setRecommendationScores] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);
  // End of Recommendation Variables

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  useEffect(() => {
    // Authenticate user and fetch data
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const companyId = await getSelectedCompanyId(firebaseUser.uid);
          const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, companyId);
          const userDocSnapshot = await getDoc(userDocRef);
          const userData = userDocSnapshot.data();
          const isSandboxUser = checkSandBoxStatus(companyId);

          if (isSandboxUser) {
            setSandboxUserQueryParameter("&demo=True");
          }

          setUser({
            firebaseUser: firebaseUser,
            uid: companyId,
            displayName: firebaseUser.displayName,
            email: firebaseUser.email,
            isDemoUser: userData?.isDemoUser ?? true,
            userTier: userData?.userTier ?? true,
            isSandboxUser: isSandboxUser,
          });

          // Validate payment and fetch recommendations
          const hasPaid = await validatePayment(firebaseUser.uid);
          if (!hasPaid) {
            window.location.href = "/onboarding";
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.error(
            "Error during authentication and data fetching:",
            error
          );
        }
      } else {
        window.location.href = "/signin";
      }
    });
  }, []);

  const fetchProcessedMessages = async (companyId) => {
    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-total-messages");
    const url = `${endpointUrl}?company_uuid=${companyId}${sandboxUserQueryParameter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const data = await response.json();
      setProcessedMessages(data["processed_rows"] || 0);
    } catch (error) {
      console.error("Error fetching processed messages:", error);
      setProcessedMessages(0);
    }
  };

  const getTotalDataPoints = async (companyId) => {
    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-total-data-points");
    const url = `${endpointUrl}?company_uuid=${companyId}${sandboxUserQueryParameter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const data = await response.json();
      setTotalDataPoints(data);
      console.log(totalDataPoints);
    } catch (error) {
      console.error("Error fetching processed messages:", error);
    }
  };

  async function getOverallScoresAndBenchmark(companyId, filters) {
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
  
    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-overall-score-averages-and-benchmark"); // Updated endpoint name
      const url = `${endpointUrl}?company_uuid=${companyId}${filterDateConditions.join(
        ""
      )}${sandboxUserQueryParameter}`;
  
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });
  
      const data = await response.json();
      setOverallScoresAndBenchmark(data);
    } catch (error) {
      console.error("Error fetching overall scores and benchmark:", error);
      setOverallScoresAndBenchmark(null);
    }
  }

  const getExtremeMessages = async (companyId, filters) => {
    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-extreme-messages");
    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
    const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
      ""
    )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const data = await response.json();
    } catch (error) {
      console.error("Error fetching processed messages:", error);
    }
  };

  async function getChannelScores(companyId, filters) {
    setScoreByTopData(null);
    setScoreByBottomData(null);
    setScoreByTeamData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
    const score_type = filters.scoreType.toLowerCase() + "_score";

    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-department-scores");
    const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
      ""
    )}${filterDateConditions.join(
      ""
    )}&score_category=${score_type}${sandboxUserQueryParameter}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((response) => {
        const digestedMap = response
          .map((x) => ({
            Department: x["department"],
            average: x["scores"][filters.scoreType.toLowerCase() + "_score"],
          }))
          .filter((x) => x.average !== null);
        const dataLength = digestedMap.length;
        setScoreByTeamData(_.sampleSize(digestedMap, 10));
        setScoreByTopData(digestedMap.slice(0, 10));
        setScoreByBottomData(
          digestedMap.slice(dataLength - 10, dataLength).reverse()
        );
      })
      .catch((error) => {
        setScoreByTeamData([]);
        setScoreByTopData([]);
        setScoreByBottomData([]);
      });
  }

  function getColorForTeam(teamName) {
    if (!teamColorMap[teamName]) {
      teamColorMap[teamName] = getRandomColor();
    }
    return teamColorMap[teamName];
  }

  async function getScoreTrendDayOverDay(companyId, filters, teams) {
    setIsTimeDataLoading(true);
    setScoreTrendDayOverDayData(null);
    setMinimalScoreTrendLineData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    const regex = /([&?])department=[^&]*(&|$)/;
    const queryConditionsCleaned = queryConditions
      .join("")
      .replace(regex, (match, p1, p2) => (p1 === "?" && p2 === "&" ? "?" : p1));

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-bucketed-averages");
      const url = `${endpointUrl}?company_uuid=${companyId}${queryConditionsCleaned}${filterDateConditions.join(
        ""
      )}${sandboxUserQueryParameter}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const responseData = await response.json();
      
      const labels = [
        ...new Set(
          responseData.map((item) => {
            const date = new Date(item["bucket_start"]);
            return date.getFullYear() +
              "-" +
              String(date.getUTCMonth() + 1).padStart(2, "0") +
              "-" +
              String(date.getUTCDate()).padStart(2, "0");
          })
        ),
      ].sort();

      const datasets = {};
      const overallScores = Array(labels.length).fill(0);
      const countScores = Array(labels.length).fill(0);

      responseData.forEach((item) => {
        const date = new Date(item["bucket_start"]);
        const formattedDate =
          date.getFullYear() +
          "-" +
          String(date.getUTCMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getUTCDate()).padStart(2, "0");
        
        if (!datasets[item["department"]]) {
          datasets[item["department"]] = {
            label: item["department"],
            data: Array(labels.length).fill(null),
            borderColor: getColorForTeam(item["department"]),
          };
        }

        const index = labels.indexOf(formattedDate);
        if (index !== -1) {
          const avgScore = item["avg_" + filters.scoreType.toLowerCase() + "_score"];
          datasets[item["department"]].data[index] = avgScore || null;
          if (avgScore !== null) {
            overallScores[index] += avgScore;
            countScores[index]++;
          }
        }
      });

      setTeamNames(Object.keys(datasets));

      let departments = [];
      if (teams && teams.length > 0) {
        departments = teams.map((team) => datasets[team]);
      }

      const departmentsFormatted = formatDepartments(departments);
      const companyAverage = getCompanyAverage(overallScores, countScores);
      departmentsFormatted.push(companyAverage);

      setScoreTrendDayOverDayData({
        labels: labels,
        datasets: departmentsFormatted,
      });

      setMinimalScoreTrendLineData({
        labels: labels,
        datasets: [companyAverage],
      });

    } catch (error) {
      console.error("Error fetching score trend data:", error);
      setScoreTrendDayOverDayData([]);
      setMinimalScoreTrendLineData(null);
    } finally {
      setIsTimeDataLoading(false);
    }
  }

  async function getScoreMetadata(companyId, filters) {
    setScoreDistributionData(null);
    setScoreMetadata(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    const idToken = await auth.currentUser.getIdToken();
    const endpointUrl = getApiUrl("get-score-metadata");
    const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
      ""
    )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      mode: "cors",
    })
      .then((response) => response.json())
      .then((response) => {
        setScoreMetadata(response);
      })
      .catch((error) => {
        setScoreMetadata([]);
      });
  }

  async function getMessageScoreAverages(companyId, filters) {
    setIsScoreDataLoading(true);
    setMessageScoreAveragesData(null);

    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-message-score-averages");
      const url = `${endpointUrl}?company_uuid=${companyId}${filterDateConditions.join(
        ""
      )}${sandboxUserQueryParameter}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const responseData = await response.json();
      setMessageScoreAveragesData(responseData);
    } catch (error) {
      console.error("Error fetching message score averages:", error);
      setMessageScoreAveragesData(null);
    } finally {
      setIsScoreDataLoading(false);
    }
  }

  async function getHeatmapData(companyId, filters) {
    setHeatmapData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
    console.log(filterDateConditions);

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-department-scores");
      const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

      const heatmapResponse = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const response = await heatmapResponse.json();
      setHeatmapData(response);
    } catch (error) {
      setHeatmapData(null);
    }
  }

  async function getEmployeeScoreContributions(companyId, filters) {
    setIsContributionsLoading(true);
    setNegativeScoreEmployeeContributions(null);
    setPositiveScoreEmployeeContributions(null);
    setNeutralScoreEmployeeContributions(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );

    try {
      const idToken = await auth.currentUser.getIdToken();
      
      // Fetch negative scores
      const negativeEndpointUrl = getApiUrl("get-negative-score-unique-employees");
      const negativeUrl = `${negativeEndpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

      const negativeResponse = await fetch(negativeUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });
      const negativeData = await negativeResponse.json();
      setNegativeScoreEmployeeContributions(negativeData);

      // Fetch positive scores
      const positiveEndpointUrl = getApiUrl("get-positive-score-unique-employees");
      const positiveUrl = `${positiveEndpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

      const positiveResponse = await fetch(positiveUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });
      const positiveData = await positiveResponse.json();
      setPositiveScoreEmployeeContributions(positiveData);

      // Fetch neutral scores
      const neutralEndpointUrl = getApiUrl("get-neutral-score-unique-employees");
      const neutralUrl = `${neutralEndpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join("")}${sandboxUserQueryParameter}`;

      const neutralResponse = await fetch(neutralUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });
      const neutralData = await neutralResponse.json();
      setNeutralScoreEmployeeContributions(neutralData);

    } catch (error) {
      console.error("Error fetching employee score contributions:", error);
      setNegativeScoreEmployeeContributions([]);
      setPositiveScoreEmployeeContributions([]);
      setNeutralScoreEmployeeContributions([]);
    } finally {
      setIsContributionsLoading(false);
    }
  }

  async function getFilteredEmployeeQuery(companyId, filters) {
    let queryConditions = [];
    for (const key in filters) {
      if (
        filters[key] &&
        key !== "scoreType" &&
        key !== "dateRange" &&
        key !== "start_date" &&
        key !== "end_date" &&
        key !== "generation"
      ) {
        queryConditions.push("&" + key + "=" + filters[key]);
      }
    }
    return queryConditions;
  }

  const handleFilterChange = useCallback(
    (filters) => {
      const currentCompanyId = user?.uid;
      if (currentCompanyId === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        filters = { ...filters, location: "Belgrade" };
      }

      if (filters.scoreType && filters.scoreType !== scoreType) {
        handleScoreTypeChange(filters.scoreType);
      }

      getTotalDataPoints(currentCompanyId);
      getExtremeMessages(currentCompanyId, filters);
      fetchProcessedMessages(currentCompanyId);
      getChannelScores(currentCompanyId, filters);
      getScoreTrendDayOverDay(currentCompanyId, filters, additionalTeams);
      // getScoreMetadata(currentCompanyId, filters); Currently being replaced by getMessageScoreAverages
      getMessageScoreAverages(currentCompanyId, filters);
      getHeatmapData(currentCompanyId, filters);
      getEmployeeScoreContributions(currentCompanyId, filters);
      getOverallScoresAndBenchmark(currentCompanyId, filters);
      setFilters(filters);
    },
    [scoreType, additionalTeams, handleScoreTypeChange, user]
  );

  const handleLineChartTeamsChange = useCallback(
    async (teams) => {
      await getScoreTrendDayOverDay(user?.uid, filters, teams);
    },
    [filters, user]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
        <Loader
          loading={isLoading}
          size={50}
          color={"#123abc"}
          loadingText={"Loading Dashboard"}
        >
          <FilterOptions
            user={user}
            companyUid={user?.uid}
            onFilterChange={handleFilterChange}
          />

          <Reports
            data={{ totalMessages: processedMessages }}
            totalDataPoints={{ totalDataPoints }}
            timeData={{ 
              scoreTrendDayOverDayData: minimalScoreTrendLineData,
              isLoading: isTimeDataLoading 
            }}
            teams={teamNames}
            handleTeamChange={handleLineChartTeamsChange}
            messageScoreAveragesData={messageScoreAveragesData}
            isScoreDataLoading={isScoreDataLoading}
            overallScoresAndBenchmark={overallScoresAndBenchmark}
            negativeScoreEmployeeContributions={negativeScoreEmployeeContributions}
            positiveScoreEmployeeContributions={positiveScoreEmployeeContributions}
            neutralScoreEmployeeContributions={neutralScoreEmployeeContributions}
            isContributionsLoading={isContributionsLoading}
            filters={filters}
            isDemoUser={user?.isDemoUser}
          />
          <div className="h-16"></div>
        </Loader>
      </Layout>
    </LocalizationProvider>
  );
}