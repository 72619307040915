import React, { useState, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loader from "../utils/Loader";
import "react-toggle/style.css";
import { auth } from "../firebase";
import { getFilteredEmployeeQuery } from "../utils/FilterUtils";
import { getFilterDatesConditions } from "../utils/DateRange";
import { getApiUrl } from "../utils/apiConfig";
import Layout from "../containers/Layout";
import BarData from "../components/BarData";
import FilterOptions from "../components/FilterOptions";
import useAuthenticatedUser from "../hooks/AuthenticateUser";
import TitleCard from "../components/Cards/TitleCard";

export default function TeamCharts() {
  const { user, sandboxUserQueryParameter, isLoading } = useAuthenticatedUser();
  const [filters, setFilters] = useState({ scoreType: "Sentiment" });
  const [teamChartData, setTeamChartData] = useState(null);
  const [scoreType, setScoreType] = useState("Sentiment");
  const [isChartLoading, setIsChartLoading] = useState(false);

  const handleScoreTypeChange = useCallback((newScoreType) => {
    setScoreType(newScoreType);
  }, []);

  async function getChannelScores(companyId, filters) {
    setIsChartLoading(true);
    setTeamChartData(null);

    const queryConditions = await getFilteredEmployeeQuery(companyId, filters);
    const filterDateConditions = getFilterDatesConditions(
      filters.start_date,
      filters.end_date
    );
    const score_type = filters.scoreType.toLowerCase() + "_score";

    try {
      const idToken = await auth.currentUser.getIdToken();
      const endpointUrl = getApiUrl("get-department-scores");
      const url = `${endpointUrl}?company_uuid=${companyId}${queryConditions.join(
        ""
      )}${filterDateConditions.join(
        ""
      )}&score_category=${score_type}${sandboxUserQueryParameter}`;

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      });

      const responseData = await response.json();
      const digestedMap = responseData
        .map((x) => ({
          Department: x["department"],
          average: x["scores"][filters.scoreType.toLowerCase() + "_score"],
        }))
        .filter((x) => x.average !== null)
        .sort((a, b) => b.average - a.average); // Sort by average score

      const dataLength = digestedMap.length;
      const data = {
        scoreByTopData: digestedMap.slice(0, 10),
        scoreByBottomData: digestedMap
          .slice(Math.max(dataLength - 10, 0), dataLength)
          .reverse(),
      };
      setTeamChartData(data);
    } catch (error) {
      console.error("Error fetching channel scores:", error);
      setTeamChartData(null);
    } finally {
      setIsChartLoading(false);
    }
  }

  const handleFilterChange = useCallback(
    (filters) => {
      const currentCompanyId = user?.uid;
      if (currentCompanyId === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
        filters = { ...filters, location: "Belgrade" };
      }

      if (filters.scoreType && filters.scoreType !== scoreType) {
        handleScoreTypeChange(filters.scoreType);
      }

      getChannelScores(currentCompanyId, filters);
      setFilters(filters);
    },
    [scoreType, handleScoreTypeChange, user]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
        <Loader
          loading={isLoading}
          size={50}
          color={"#123abc"}
          loadingText={"Generating Team Data"}
        >
          <div className="space-y-6">
            <div className="pt-4">
              <FilterOptions
                user={user}
                companyUid={user?.uid}
                onFilterChange={handleFilterChange}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <TitleCard title={`Top Performing Teams - ${filters.scoreType}`}>
                <div className="p-4">
                  <Loader
                    loading={isChartLoading}
                    componentLoading={true}
                    size={30}
                    color={"#4a90e2"}
                    loadingText={"Loading Top Team Data"}
                  >
                    <div className="h-[400px]">
                      <BarData
                        title={`Top ${filters.scoreType} Scores`}
                        dataSet={teamChartData?.scoreByTopData || []}
                        color="#4a90e2"
                      />
                    </div>
                  </Loader>
                </div>
              </TitleCard>

              <TitleCard title={`Bottom Performing Teams - ${filters.scoreType}`}>
                <div className="p-4">
                  <Loader
                    loading={isChartLoading}
                    componentLoading={true}
                    size={30}
                    color={"#e25c4a"}
                    loadingText={"Loading Bottom Team Data"}
                  >
                    <div className="h-[400px]">
                      <BarData
                        title={`Bottom ${filters.scoreType} Scores`}
                        dataSet={teamChartData?.scoreByBottomData || []}
                        color="#e25c4a"
                      />
                    </div>
                  </Loader>
                </div>
              </TitleCard>
            </div>
          </div>
        </Loader>
      </Layout>
    </LocalizationProvider>
  );
}